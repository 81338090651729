<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog" :persistent="loadingForm">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }} <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close" :disabled="loadingForm">
          <v-icon>{{ icons.close }}</v-icon></v-btn
        >
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-row>
            <!-- CAMPO NOMBRE -->
            <v-col cols="12">
              <v-text-field
                v-model="form.pais_descripcion"
                name="pais"
                label="Nuevo país"
                placeholder="Escriba el nombre del país..."
                id="id_pais"
                :rules="[(v) => !!v || 'El campo nombre país es requerido']"
              ></v-text-field>
            </v-col>

            <!-- CAMPO CODIGO -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.codigo"
                name="codigo"
                label="Código del pais"
                placeholder="Escriba código del país..."
                id="id_pais"
                :rules="[(v) => !!v || 'El campo código es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO VALOR -->
            <v-col cols="12" md="6">
              <v-text-field
                v-model="form.valor_impuesto"
                name="valor_impuesto"
                label="Valor Impuesto"
                placeholder="Escriba valor impuesto..."
                id="id_pais"
                type="number"
                :rules="[(v) => !!v || 'El campo valor impuesto es requerido.']"
              >
              </v-text-field>
            </v-col>

            <!-- CAMPO MONEDA -->
            <v-col cols="12">
              <v-autocomplete
                v-model="form.moneda_destino"
                :items="monedas"
                item-text="moneda_descripcion"
                item-value="moneda"
                item-disabled="eliminado"
                label="Moneda Destino"
                :rules="[(v) => !!v || 'Debe seleccionar una moneda.']"
              ></v-autocomplete>
            </v-col>

            <!-- IMPUESTO -->
            <v-col cols="12">
              <v-autocomplete
                v-model="form.tipo_impuesto"
                :items="impuestos"
                item-text="valor"
                item-value="_id"
                label="Tipo de impuesto"
                :rules="[(v) => !!v || 'Debe seleccionar un impuesto.']"
              ></v-autocomplete>
            </v-col>
            <!-- item-disabled="eliminado" -->
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit" :loading="loadingForm">{{
          labels.submit
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  name: "PaisesFormModal",
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de paises",
      loadingForm: false,
      loadingMonedas: false,
      loadingImpuestos: false,
      form: {
        pais_descripcion: "",
        codigo: "",
        valor_impuesto: 0,
        moneda_destino: "",
        tipo_impuesto: "",
      },
      monedas: [],
      impuestos: [],
    };
  },
  methods: {
    ...mapActions("NewProduct", [
      "CrearPais",
      "UpdatePais",
      "getMoneda",
      "getImpuesto",
    ]),

    setup() {
      this.loadingMonedas = true;
      this.loadingImpuestos = true;

      this.getMoneda()
        .then((response) => {
          this.monedas = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingMonedas = false;
        });

      this.getImpuesto()
        .then((response) => {
          this.impuestos = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loadingImpuestos = false;
        });
    },
    async send() {
      this.loadingForm = true;
      let method = this.CrearPais;
      const formData = {
        ...this.form,
      };
      if (this.formData) {
        formData.id = this.formData.pais;
        method = this.UpdatePais;
      }
      try {
        const response = await method(formData);
        this.snackbar(response.info, "success");
        this.$emit("submit");
      } catch (error) {
        this.snackbar(error.info);
      }
      this.loadingForm = false;
    },
  },
};
</script>
