<template>
  <v-container>
    <v-card>
      <v-data-table v-bind="bind.table" :search="search">
        <template #top>
          <div class="d-flex pa-3">
            <v-text-field v-bind="bind.search" v-model="search"></v-text-field>
            <v-divider v-bind="bind.divider"></v-divider>

            <v-spacer></v-spacer>
            <v-btn v-bind="bind.refresh" v-on="on.refresh">
              <v-icon>{{ icons.refresh }}</v-icon>
            </v-btn>
            <v-btn v-bind="bind.new" v-on="on.new">{{ labels.new }}</v-btn>
          </div>
        </template>

        <template #item.eliminado="{ item }">
          <v-chip
            class="ma-2"
            small
            :color="item.eliminado ? 'grey lighten-2' : 'primary'"
          >
            <v-icon x-small class="mr-1">mdi-checkbox-blank-circle</v-icon>
            {{ item.eliminado ? "Inactivo" : "Activo" }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <v-btn
            color="info"
            v-bind="bind.actions"
            @click="activateItem(item)"
            :disabled="!item.eliminado"
          >
            <v-icon>{{ icons.activate }}</v-icon>
          </v-btn>
          <v-btn v-bind="bind.actions" v-on="on.edit(item)">
            <v-icon>{{ icons.edit }}</v-icon>
          </v-btn>
          <v-btn
            color="error"
            v-bind="bind.actions"
            v-on="on.delete(item)"
            :disabled="item.eliminado"
          >
            <v-icon>{{ icons.delete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <form-paises v-bind="bind.form" v-on="on.form" v-model="dialog" />
    <confirm-modal :ref="refs.confirm" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PageMixin from "@/Mixins/SetPageMixin.js";
import Modal from "@/components/Modal/ModalProduct/Modal-Pais.vue";
export default {
  name: "PaisesCrudPage",
  mixins: [PageMixin],
  components: {
    "form-paises": Modal,
  },
  data: () => ({
    paises: [],
    headers: [
      { text: "Código", value: "codigo", align: "left" },
      { text: "País", value: "pais_descripcion", align: "left" },
      { text: "Valor Impuesto", value: "valor_impuesto", align: "center" },
      { text: "Estado", value: "eliminado", align: "center" },
      { text: "", value: "actions", align: "right", sortable: false },
    ],
  }),
  computed: {
    items() {
      return this.paises;
    },
  },
  methods: {
    ...mapActions("NewProduct", [
      "getPaises",
      "ActivateCountry",
      "deletePaises",
    ]),

    setup() {
      this.loading = true;
      this.getPaises()
        .then((response) => {
          this.paises = response;
        })
        .catch((error) => {
          this.snackbar(error.info);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    saveItem() {
      this.closeForm();
      this.setup();
    },

    activateItem(item) {
      this.ActivateCountry(item.pais)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.info);
        });
    },

    deleteItem(item) {
      this.deletePaises(item.pais)
        .then((response) => {
          this.snackbar(response.info, "success");
          this.setup();
        })
        .catch((error) => {
          this.snackbar(error.info);
        });
    },
  },
};
</script>
